<template>
  <section class="section faq">
    <h2>{{ $t("FAQ") }}</h2>
    <div class="faq-wrapper">
      <SelectTab
        v-if="isShowTab"
        class="faq__select-tab"
        :options="targetUserOptions"
        :value="targetUser"
        @select="selectTargetUser"
      >
        <template #option="slotProps">
          {{ $t(slotProps.option.value) }}
        </template>
      </SelectTab>
      <BaseSelect
        v-if="isShowTab"
        class="faq__select"
        :options="targetUserOptions"
        :model-value="targetUserSelectValue"
        styled
        arrow
        @change="selectTargetUser"
      >
        <template #value> {{ $t(targetUserSelectValue.value) }}</template>
        <template #option="levelsOptionalList">
          {{ $t(levelsOptionalList.option.value) }}
        </template>
      </BaseSelect>
      <div class="faq-container">
        <div class="faq-list">
          <div v-show="targetUser !== User.members" class="faq-links">
            <span class="text-h5-new">
              {{
                $t(
                  isCompany
                    ? "Информация для компаний"
                    : "Информация для партнеров"
                )
              }}
            </span>

            <LocLink
              :to="isCompany ? '/companies' : '/partners'"
              @click="sendNavEvent"
            >
              <span>
                {{ $t(isCompany ? "Для компаний" : "Для Партнеров") }}
              </span>
              <BaseIcon icon="arrow" height="24" width="24" />
            </LocLink>
          </div>
          <ExpansionItem
            v-for="(item, i) in $tm(`faq_section.${page || targetUser}`)"
            :key="`faq-${targetUser}-${i}`"
            :title="$rt(item.question)"
            :text="$rt(item.answer)"
            @click="sendQuestionEvent($rt(item.question))"
          />
        </div>
        <div class="faq-form">
          <h5>
            {{ $t("Can't find the answer to your question?") }}
          </h5>
          <BaseButton text @click="showModalForm">
            {{ $t("Ask Us Anything") }}
          </BaseButton>
        </div>
      </div>
    </div>
    <LazyModalsAskQuestionFormModal v-if="isShowModal" v-model:is-show-modal="isShowModal" />
  </section>
</template>
<script lang="ts">
import { PropType } from "vue";
import { User } from "~/types/entities";

import BaseSelect, {
  BaseSelectOption,
} from "~/components/input/BaseSelect.vue";
import BaseButton from "~/components/button/BaseButton.vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";
import SelectTab from "~/components/input/SelectTab.vue";
import ExpansionItem from "~/components/expansionItem/ExpansionItem.vue";
import LocLink from "~/components/lang/LocLink.vue";

const TARGET_USER_OPTIONS_LIST: BaseSelectOption[] = [
  { value: "For Members", id: User.members },
  { value: "For Partners", id: User.partners },
  { value: "For Companies", id: User.companies },
];

export default defineNuxtComponent({
  components: {
    LocLink,
    BaseIcon,
    BaseButton,
    ExpansionItem,
    SelectTab,
    BaseSelect,
  },
  props: {
    page: {
      type: String as PropType<User>,
      default: null,
    },
  },
  setup() {
    const { gtag } = useGtag();
    const route = useRoute();
    const localePath = useLocalePath();
    const isShowModal = ref(false);
    const targetUser = ref(TARGET_USER_OPTIONS_LIST[0].id);
    const targetUserSelectValue = ref(TARGET_USER_OPTIONS_LIST[0]);
    const isShowTab = computed(() => route.fullPath === localePath("/"));

    const imageLink = "/image/faq-image.svg";

    function showModalForm() {
      isShowModal.value = true;
      gtag("event", FAQEvents.askQuestionModal);
    }

    const isCompany = computed(() => targetUser.value === User.companies);
    const targetUserOptions = computed(() => TARGET_USER_OPTIONS_LIST);

    const sendQuestionEvent = (question: string) => {
      gtag("event", FAQEvents.showAnswer, { question: question });
    };
    const sendNavEvent = () => {
      const event = isCompany.value
        ? FAQEvents.toCompaniesPage
        : FAQEvents.toPartnersPage;
      gtag("event", event);
    };

    function selectTargetUser(selectedOption: BaseSelectOption | User) {
      if (typeof selectedOption === "string") {
        targetUser.value = selectedOption;
      } else {
        targetUser.value = selectedOption.id;
        targetUserSelectValue.value = selectedOption;
      }

      gtag("event", FAQEvents[targetUser.value as User]);
    }

    return {
      User,
      targetUser,
      isShowTab,
      targetUserSelectValue,
      isShowModal,
      imageLink,
      targetUserOptions,
      isCompany,
      showModalForm,
      selectTargetUser,
      sendQuestionEvent,
      sendNavEvent,
    };
  },
});
</script>
